<template>
  <header :class="headerClass">
    <slot />
  </header>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
type Position = 'fixed' | 'absolute' | 'relative' | 'sticky' | ''
const props = defineProps({
  position: {
    type: String as PropType<Position>,
    default: ''
  },
  className: null as unknown as PropType<unknown>
})
const basicClass = 'com-header'
const headerClass = computed(() => {
  return [basicClass, `${basicClass}-${props.position}`, props.className]
})
</script>
<style lang="scss" scoped>
.com-header {
  width: 100%;
  height: 80px;
  background-color: #141414;
  z-index: 99;
  &-fixed {
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
    bottom: auto;
  }
  &-absolute {
    position: absolute;
    top: 0;
    left: auto;
    right: auto;
    bottom: auto;
  }
  &-relative {
    position: relative;
    top: 0;
    left: auto;
    right: auto;
    bottom: auto;
  }
  &-sticky {
    position: sticky;
    top: 0;
    left: auto;
    right: auto;
    bottom: auto;
  }
}
@media not all and (min-width: 1024px) {
  .com-header {
    height: 120px;
  }
}
</style>
